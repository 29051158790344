import React from 'react'
import { Grid } from '@mui/material'
import { Apple as AppleIcon } from '@mui/icons-material'
import GPlayIcon from '../../images/g-play-logo.png'
import IGIcon from '../../images/ig-logo.png'
import FBIcon from '../../images/fb-logo.png'
import {
    LinkBox,
    LabelBox,
    LabelText,
    LabelName,
    StyledLinkFooter,
    StyledLinkFooterBlack,
    CopyrightText,
    FooterTitle,
    FooterText,
    FooterInfoBox,
    StyledIconFooter,
    StyledLinkFooterFB,
    StyledLinkFooterIG,
    StyledLinkFooterRight,
    StyledLinkFooterLeft,
    StyledLinkPersonalConditions,
    PersonalConditionsText,
    SocialIcon,
} from '../../styles'

export const Footer = () => {
    return (
        <>
            <Grid container alignItems="center" justifyContent="center">
                <Grid item xs={12}>
                    <FooterTitle
                        variant="body1"
                        color="textPrimary"
                        align="center"
                    >
                        eValue.cz s.r.o.
                    </FooterTitle>
                    <FooterInfoBox>
                        <FooterText
                            variant="caption"
                            color="textPrimary"
                            align="center"
                        >
                            Na Vyhlídce 1590/4
                        </FooterText>
                        <FooterText
                            variant="caption"
                            color="textPrimary"
                            align="center"
                        >
                            669 02 Znojmo
                        </FooterText>
                    </FooterInfoBox>
                    <FooterInfoBox>
                        <StyledLinkFooter href="tel:+420776167680">
                            <FooterText
                                variant="caption"
                                color="textPrimary"
                                align="center"
                            >
                                +420 776 167 680
                            </FooterText>
                        </StyledLinkFooter>
                        <StyledLinkFooter href="mailto:info@firealarm.cz">
                            <FooterText
                                variant="caption"
                                color="textPrimary"
                                align="center"
                            >
                                info@firealarm.cz
                            </FooterText>
                        </StyledLinkFooter>
                    </FooterInfoBox>
                </Grid>
                <Grid item xs={6}>
                    <StyledLinkFooterFB
                        href="https://www.facebook.com/appfirealarm"
                        target="_blank"
                    >
                        <SocialIcon src={FBIcon} />
                    </StyledLinkFooterFB>
                </Grid>
                <Grid item xs={6}>
                    <StyledLinkFooterIG
                        href="https://www.instagram.com/firealarm_czech/"
                        target="_blank"
                    >
                        <SocialIcon src={IGIcon} />
                    </StyledLinkFooterIG>
                </Grid>
                <Grid item xs={6}>
                    <StyledLinkFooterRight href="https://apps.apple.com/cz/app/firealarm/id1581001537?l=cs">
                        <LinkBox>
                            <StyledIconFooter>
                                <AppleIcon sx={{ fontSize: '18px' }} />
                            </StyledIconFooter>
                            <LabelBox>
                                <LabelText>Available on the</LabelText>
                                <LabelName>App Store</LabelName>
                            </LabelBox>
                        </LinkBox>
                    </StyledLinkFooterRight>
                </Grid>
                <Grid item xs={6}>
                    <StyledLinkFooterLeft href="https://play.google.com/store/apps/details?id=cz.evalue.FireAlarm">
                        <LinkBox>
                            <StyledIconFooter
                                src={GPlayIcon}
                                alt="google play icon"
                                sx={{
                                    '& > img': {
                                        height: '19px',
                                        width: 'auto',
                                    },
                                }}
                            />
                            <LabelBox>
                                <LabelText>GET IN ON</LabelText>
                                <LabelName>Google Play</LabelName>
                            </LabelBox>
                        </LinkBox>
                    </StyledLinkFooterLeft>
                </Grid>
                <Grid item xs={12}>
                    <PersonalConditionsText align="center">
                        <StyledLinkPersonalConditions
                            href="/FireAlarmPrivacyPolicy.pdf"
                            target="_blank"
                        >
                            Ochrana osobních údajů
                        </StyledLinkPersonalConditions>
                    </PersonalConditionsText>
                    <CopyrightText variant="body2" align="center" paragraph>
                        Copyright © {new Date().getFullYear()}
                        <StyledLinkFooterBlack
                            href="https://www.evalue.cz/"
                            target="_blank"
                        >
                            www.evalue.cz
                        </StyledLinkFooterBlack>
                    </CopyrightText>
                </Grid>
            </Grid>
        </>
    )
}
