import styled from '@mui/system/styled'
import Pagination from '@mui/material/Pagination'
import { colors } from '../constants'

export const StyledPagination = styled(Pagination)({
    margin: '0 auto',
    maxWidth: '650px',
    paddingBottom: '25px',
    ul: {
        justifyContent: 'center',
        '& > li': {
            background: 'transparent',
            borderRadius: '30px',
            boxShadow: `0px 2px 12px -7px ${colors.black}`,
            margin: '0 5px',
            '& > .Mui-selected': {
                backgroundColor: 'transparent',
                border: `2px solid ${colors.black}`,
                outline: 'none',
            },
        },
    },
})
