import React, { useState, ChangeEvent } from 'react'
import {
    DescriptionPrankText,
    StyledPrankLabel,
    StyledRadio,
    SubmitPrankButton,
} from '../../styles'
import Box from '@mui/material/Box'
import FormControl from '@mui/material/FormControl'
import { RadioGroup } from '@mui/material'

const questions = [
    {
        id: 1,
        question: 'Bojíte se umělé inteligence 😱?',
    },
    {
        id: 2,
        question: 'Bojí se umělá inteligence vás 🤖?',
    },
]

export const PrankSurvey = () => {
    const [, setValue] = useState('')

    const handleRadioChange = (event: ChangeEvent<HTMLInputElement>) => {
        setValue((event.target as HTMLInputElement).value)
    }
    return (
        <FormControl variant="standard">
            {questions?.map(({ id, question }) => (
                <>
                    <DescriptionPrankText
                        key={id}
                        variant="h5"
                        color="white"
                        textAlign="left"
                    >
                        {id}. {question}
                    </DescriptionPrankText>
                    <RadioGroup>
                        <StyledPrankLabel
                            value="ano"
                            control={
                                <StyledRadio onChange={handleRadioChange} />
                            }
                            label="Ano"
                        />
                        <StyledPrankLabel
                            value="ne"
                            control={
                                <StyledRadio onChange={handleRadioChange} />
                            }
                            label="Ne"
                        />
                    </RadioGroup>
                </>
            ))}
            <Box display="flex" flexDirection="column" alignItems="center">
                <SubmitPrankButton
                    type="submit"
                    variant="contained"
                    color="inherit"
                >
                    <DescriptionPrankText
                        variant="h5"
                        color="white"
                        textAlign="left"
                    >
                        Jsem připraven/a! 😎
                    </DescriptionPrankText>
                </SubmitPrankButton>
            </Box>
        </FormControl>
    )
}
