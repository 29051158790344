import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import { StyledLink } from '../../styles'

interface Params {
    id?: string
}

export const Confirmation = () => {
    const params = useParams<Partial<Params>>()
    const [subscriptionStatus, setIsConfirmed] = useState('no')

    useEffect(() => {
        async function confirmSubscription() {
            try {
                await fetch(`/api/subscribe/confirm/${params.id}`)

                setIsConfirmed('yes')
            } catch (exception) {
                setIsConfirmed('error')
            }
        }

        confirmSubscription()
    }, [params.id])

    return (
        <Grid container justifyContent="center">
            <Grid item justifyContent="center">
                <Paper elevation={3}>
                    <Box
                        display="flex"
                        justifyContent="center"
                        flexDirection="column"
                        padding={5}
                    >
                        <Typography variant="h5" gutterBottom component="div">
                            {subscriptionStatus === 'no' ? 'Načítání...' : null}
                            {subscriptionStatus === 'yes'
                                ? 'Odběr událostí byl potvrzen'
                                : null}
                        </Typography>
                        <StyledLink href="/">Zpět</StyledLink>
                    </Box>
                </Paper>
            </Grid>
        </Grid>
    )
}
