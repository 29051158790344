import React, { useEffect, useState, useContext, FC } from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'
import { AppStateContext } from '../../AppState'
import { District } from '../../common'
import { colors } from '../../constants'
import Box from '@mui/material/Box'
import Autocomplete from '@mui/material/Autocomplete'
import { KeyboardArrowDown as KeyboardArrowDownIcon } from '@mui/icons-material'
import {
    CopyWrapper,
    Description,
    Iframe,
    IframeInfo,
    IframeTitle,
    StyledLabel,
    CopyButton,
    StyledInput,
} from '../../styles'

interface CreateIframe {
    setNotification: (title: string) => void
}

const urlBase = `${window.location.origin}/api/embeded?`

export const CreateIframeMode: FC<CreateIframe> = (props) => {
    const { setNotification } = props

    const [urlSearch, setUrlSearch] = useState<string>('')
    const [selectedUnits] = useState<Array<string>>([])
    const [selectedDistricts, setSelectedDistricts] = useState<Array<number>>(
        []
    )
    const { districts } = useContext(AppStateContext)

    const districtsOptions = districts?.map((district: District) => ({
        label: district?._name ?? '',
        value: district.id,
    }))

    useEffect(() => {
        let url = ''

        if (selectedUnits.length) {
            url += `units=${encodeURIComponent(
                selectedUnits.map((u) => u).toString()
            )}`
        }

        if (selectedDistricts.length) {
            url += `districts=${encodeURIComponent(
                selectedDistricts.map((u) => u).toString()
            )}`
        }

        setUrlSearch(url)
    }, [selectedUnits, selectedDistricts])

    return (
        <Iframe>
            <IframeTitle variant="h5">
                Přehled událostí na vašich www stránkách
            </IframeTitle>
            <Description paragraph variant="body1">
                Vygenerujte si iframe url pro vložení do vašich stránek, kde
                chcete zobrazit přehled událostí konkrétního okresu
            </Description>
            <Box
                display="flex"
                alignItems="start"
                flexDirection="column"
                maxWidth={550}
                mt={5}
                mx="auto"
            >
                <StyledLabel align="left" variant="h6">
                    Okres
                </StyledLabel>
                <Autocomplete
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        width: '100%',
                        height: 40,
                        background: colors.white,
                        margin: '10px 0 20px',
                        borderRadius: '30px',
                    }}
                    disableClearable
                    noOptionsText={'Žádné možnosti'}
                    options={districtsOptions || []}
                    popupIcon={<KeyboardArrowDownIcon />}
                    getOptionLabel={(option) => option.label}
                    onChange={(_e, option) =>
                        option?.value && setSelectedDistricts([option?.value])
                    }
                    renderOption={(props, option) => (
                        <li {...props}>{option.label}</li>
                    )}
                    renderInput={(params) => (
                        <StyledInput
                            {...params}
                            variant="outlined"
                            placeholder="Vyberte okres"
                            inputProps={{
                                ...params.inputProps,
                                autoComplete: 'no-fill', // disable autocomplete and autofill
                            }}
                        />
                    )}
                />
            </Box>

            {selectedUnits.length || selectedDistricts.length ? (
                <React.Fragment>
                    <IframeInfo>
                        <code>{`<iframe src="${urlBase}${urlSearch}" width="350px" height="325px" ></iframe>`}</code>
                    </IframeInfo>
                    <CopyWrapper>
                        <CopyToClipboard
                            text={`<iframe src="${urlBase}${urlSearch}" width="350px" height="325px" ></iframe>`}
                            onCopy={() =>
                                setNotification('Iframe úspěšně zkopírován')
                            }
                        >
                            <CopyButton variant="contained" color="inherit">
                                Kopírovat
                            </CopyButton>
                        </CopyToClipboard>
                    </CopyWrapper>
                </React.Fragment>
            ) : null}
        </Iframe>
    )
}
