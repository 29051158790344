import styled from '@mui/system/styled'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Avatar from '@mui/material/Avatar'
import Link from '@mui/material/Link'
import { colors } from '../constants'

export const FooterTitle = styled(Typography)(({ theme }) => ({
    color: colors.fireRedDark,
    fontWeight: 'bold',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(1),
}))

export const FooterText = styled(Typography)({
    color: colors.black,
})

export const FooterInfoBox = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center',
}))

export const LinkBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '90px',
    height: '26px',
    borderRadius: '5px',
    pading: '1px',
    marginTop: theme.spacing(4),
    background: colors.black,
}))

export const StyledIconFooter = styled(Avatar)({
    variant: 'square',
    width: 20,
    height: 20,
    marginRight: 5,
    borderRadius: '0',
    color: colors.white,
    backgroundColor: 'black',
})

export const LabelBox = styled('div')({
    display: 'flex',
    flexDirection: 'column',
})

export const LabelText = styled('span')({
    color: colors.white,
    fontSize: '8px',
})

export const LabelName = styled('span')({
    color: colors.white,
    fontSize: '9px',
    fontWeight: 'bold',
})

export const StyledLinkFooter = styled(Link)({
    textDecoration: 'none',
    justifyContent: 'center',
})

export const SocialIcon = styled(Avatar)(({ theme }) => ({
    width: 30,
    height: 30,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    marginTop: theme.spacing(3),
    backgroundColor: colors.white,
}))

export const StyledLinkFooterFB = styled(Link)({
    textDecoration: 'none',
    justifyContent: 'right',
    display: 'flex',
})

export const StyledLinkFooterRight = styled(Link)(({ theme }) => ({
    textDecoration: 'none',
    justifyContent: 'right',
    display: 'flex',
    paddingRight: theme.spacing(2),
}))

export const StyledLinkFooterIG = styled(Link)({
    textDecoration: 'none',
    justifyContent: 'left',
    display: 'flex',
})

export const StyledLinkFooterLeft = styled(Link)(({ theme }) => ({
    textDecoration: 'none',
    justifyContent: 'left',
    display: 'flex',
    paddingLeft: theme.spacing(2),
}))

export const StyledLinkFooterBlack = styled(Link)({
    textDecoration: 'none',
    color: colors.black,
    marginLeft: '5px',
})

export const PersonalConditionsText = styled(Typography)(({ theme }) => ({
    fontSize: '12px',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
}))

export const StyledLinkPersonalConditions = styled(Link)(({ theme }) => ({
    color: colors.black,
    textDecorationColor: colors.black,
    justifyContent: 'center',
    textAlign: 'center',
}))

export const CopyrightText = styled(Typography)(({ theme }) => ({
    fontSize: '11px',
    color: colors.black,
}))
