import React from 'react'
import config from '../../config'
import {
    CopyPromoButton,
    DescriptionPrankText,
    PrankWrapper,
    StyledPrankHighlight,
    StyledPrankPaper,
    StyledPrankTitle,
} from '../../styles'
import ContentCopy from '@mui/icons-material/ContentCopy'

export const PrankPromo = () => {
    const { URL_ESHOP_MAIN } = config
    const promoValue = 'APRIL24'
    const copyToClipboard = async () => {
        await navigator.clipboard.writeText(promoValue as string)
        window.location.href = `${URL_ESHOP_MAIN}/store`
    }
    return (
        <PrankWrapper>
            <StyledPrankTitle variant="h3" color="inherit">
                🤡 Apríííííííl! 🥳
            </StyledPrankTitle>
            <DescriptionPrankText textAlign="center">
                Ať už jste se nechali nachytat nebo jste propadli zvědavosti, my
                vám to teď vynahradíme
                <StyledPrankHighlight>10% slevovým kódem</StyledPrankHighlight>
                do našeho eshopu!
            </DescriptionPrankText>
            <StyledPrankPaper>
                <DescriptionPrankText sx={{ fontSize: '32px' }}>
                    KÓD: {promoValue}
                </DescriptionPrankText>
                <PrankWrapper>
                    <CopyPromoButton onClick={copyToClipboard}>
                        <ContentCopy />
                    </CopyPromoButton>
                    <DescriptionPrankText
                        sx={{ fontSize: '16px', padding: '5px' }}
                    >
                        Zkopírovat a jít do obchodu
                    </DescriptionPrankText>
                </PrankWrapper>
            </StyledPrankPaper>
            <DescriptionPrankText textAlign="center" sx={{ fontSize: '18px' }}>
                💡 Ačkoliv jsou technologie AI již značně pokročilé a nějakou
                dobu už pomáhají třeba v oblasti predikce a včasného varování
                před živelnými katastrofami jako jsou zemětřesení, tsunami nebo
                tornáda a hurikány, lidský faktor stále zůstává nepředvídatelnou
                neznámou i pro umělou inteligenci.
            </DescriptionPrankText>
            <DescriptionPrankText
                textAlign="center"
                sx={{ fontSize: '18px', padding: '5px' }}
            >
                Prozatím...
            </DescriptionPrankText>
        </PrankWrapper>
    )
}
