import styled from '@mui/system/styled'
import Avatar from '@mui/material/Avatar'
import Typography from '@mui/material/Typography'
import { colors } from '../constants'

export const StyledImage = styled(Avatar)({
    component: 'img',
    width: 280,
    height: '100%',
    marginTop: '40px',
    variant: 'square',

    borderRadius: '0',
})
export const StyledTitle = styled(Typography)({
    color: colors.fireRed,
    margin: '25px 0 20px',
    fontWeight: 'bold',
})
export const DescriptionRegisterPage = styled(Typography)({
    maxWidth: '270px',
})
