import React, { useState, useEffect, useCallback, FC } from 'react'
import { useNavigate } from 'react-router-dom'
import { useQuery } from '../../utils'
import {
    FireType,
    QueryObject,
    TableColumnType,
    TableFilterType,
} from '../../common'
import { FireTable } from '../../components'
import { StyledPagination } from '../../styles'

const HeaderData: Array<TableColumnType> = [
    {
        header: 'Místo',
        value: 'village',
    },
    {
        header: 'Kraj',
        value: 'region',
    },
    {
        header: 'Událost',
        value: 'type',
    },
    {
        header: 'Datum',
        value: 'eventRegistrationDate',
    },
]

interface FiresProps {
    urlQuery: QueryObject
}

export const Fires: FC<FiresProps> = (props) => {
    const navigate = useNavigate()
    const { urlQuery } = props
    const {
        dateQuery,
        districtsQuery,
        limitQuery,
        pageQuery,
        sortDirectionQuery,
        sortQuery,
    } = urlQuery
    const query = useQuery()
    const history = useNavigate()

    const [fires, setFires] = useState<Array<FireType>>([])
    const [filter, setFilter] = useState<TableFilterType>({
        districts: [],
        date: '',
    })

    const [sort, setSort] = useState<string | null>('eventRegistrationDate')
    const [sortDirection, setSortDirection] = useState<string | null>('desc')
    const [page, setPage] = useState<number>(1)
    const [pageSize, setPageSize] = useState<number>(20)

    const saveStateToStorage = () => {
        localStorage.setItem('filter', JSON.stringify(filter))
    }

    const getValueFromStorage = (key: string) => {
        const value = localStorage.getItem(key)
        return value && JSON.parse(value)
    }

    useEffect(() => {
        let filterObject: TableFilterType = filter
        let filterFromStorage = getValueFromStorage('filter') || filter
        if (query.toString().length) filterFromStorage = filter
        if (districtsQuery || dateQuery) {
            if (districtsQuery) {
                filterObject.districts = districtsQuery
                    .split(',')
                    .map((d) => parseInt(d))
            }
            if (dateQuery) {
                filterObject.date = dateQuery
            }
        } else {
            filterObject = filterFromStorage
        }
        if (sortQuery) {
            setSort(sortQuery)
        }
        if (sortDirectionQuery) {
            setSortDirection(sortDirectionQuery)
        }
        if (pageQuery && !isNaN(parseInt(pageQuery))) {
            setPage(parseInt(pageQuery))
        }
        if (limitQuery && !isNaN(parseInt(limitQuery))) {
            setPageSize(parseInt(limitQuery))
        }

        setFilter(filterObject)
    }, []) // eslint-disable-line

    const fetchEvents = useCallback(
        (searchQuery: string) => {
            fetch(`/api/events${searchQuery}`).then(async (response) => {
                const data = await response.json()
                const events: Array<FireType> = data.events
                setFires(
                    events.map((f) => ({
                        ...f,
                        type: `${f.type} - ${f.subtype}`,
                        village:
                            f.village && f.street
                                ? `${f.village} - ${f.street}`
                                : `${f.village || f.street || ''}`,
                    }))
                )
            })
        },
        [districtsQuery] // eslint-disable-line
    )

    useEffect(() => {
        let searchQuery = ''

        if (filter.districts.length) {
            searchQuery += `districts=${encodeURIComponent(
                filter.districts.toString()
            )}&`
        }
        if (filter.date) {
            searchQuery += `date=${encodeURIComponent(filter.date)}&`
        }
        if (sort) {
            searchQuery += `sort=${encodeURIComponent(sort)}&`
        }
        if (sortDirection) {
            searchQuery += `sortDirection=${encodeURIComponent(sortDirection)}&`
        }
        if (page) {
            searchQuery += `page=${encodeURIComponent(page)}&`
        }
        if (pageSize) {
            searchQuery += `limit=${encodeURIComponent(pageSize)}`
        }

        searchQuery = searchQuery.length ? `?${searchQuery}` : ''
        saveStateToStorage()

        navigate(`/fires${searchQuery}`)

        fetchEvents(searchQuery)
    }, [filter, sort, sortDirection, page, pageSize, history, fetchEvents]) // eslint-disable-line

    return (
        <div>
            <FireTable
                onChangeFilter={setFilter}
                filter={filter}
                sort={sort}
                sortDirection={sortDirection}
                onChangeSortDirection={setSortDirection}
                columns={HeaderData}
                fires={fires}
            />
            <StyledPagination
                count={3500}
                defaultPage={page}
                size="small"
                page={page}
                boundaryCount={5}
                onChange={(_e, page) => setPage(page)}
            />
        </div>
    )
}
