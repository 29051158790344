import styled from '@mui/system/styled'
import Typography from '@mui/material/Typography'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import Link from '@mui/material/Link'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import { colors } from '../constants'

export const StyledAutoComplete = styled(Autocomplete)({
    display: 'flex',
    alignItems: 'center',
    width: 250,
    height: 40,
    background: colors.white,
    margin: '10px 0 20px',
    borderRadius: '30px',
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            border: 'none',
        },
    },
})
export const StyledTextfield = styled(TextField)({
    border: 'none',
    display: 'flex',
})
export const AutoCompleteLabel = styled(Typography)({
    width: 250,
    color: colors.white,
})
export const CountSelectOption = styled('div')({
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    width: '70%',
    background: colors.white,
    zIndex: 1,
    padding: '0 10px',
    fontSize: '14px',
})
export const SelectCircle = styled('span')({
    display: 'block',
    width: '15px',
    height: '15px',
    borderRadius: '100%',
    background: colors.fireRed,
    marginRight: '10px',
})
export const StyledCheckbox = styled(Checkbox)({
    color: colors.white,
    '&.Mui-checked': {
        color: colors.white,
    },
})
export const CheckboxRed = styled(Checkbox)({
    color: colors.fireRed,
    '&.Mui-checked': {
        color: colors.fireRed,
    },
})
export const CheckboxLabel = styled(FormControlLabel)({
    color: colors.white,
    '& .MuiTypography-root': {
        fontSize: '12px',
    },
})
export const StyledLink = styled(Link)({
    color: colors.fireRed,
    fontWeight: 'bold',
    cursor: 'pointer',
    textAlign: 'center',
})
