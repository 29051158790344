import styled from '@mui/system/styled'
import { colors } from '../constants'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import FormControlLabel from '@mui/material/FormControlLabel'
import Radio from '@mui/material/Radio'
import Paper from '@mui/material/Paper'
import Box from '@mui/material/Box'

export const StyledPrankTitle = styled(Typography)({
    color: colors.fireRed,
    margin: '25px 0 20px',
    fontWeight: 'bold',
    textAlign: 'center',
})

export const StyledPrankHighlight = styled(Typography)({
    display: 'inline',
    color: colors.fireRed,
    fontWeight: 'bold',
    fontSize: 'inherit',
    padding: '0 4px',
})

export const DescriptionPrankText = styled(Typography)({
    maxWidth: '800px',
    padding: '20px',
    fontSize: '26px',
})

export const StyledPrankForm = styled('form')({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    background: colors.fireRed,
    maxWidth: '800px',
    minWidth: '300px',
    borderRadius: '25px',
    padding: '15px',
    margin: '0 auto',
})

export const StyledPrankLabel = styled(FormControlLabel)({
    color: colors.white,
})

export const StyledRadio = styled(Radio)({
    marginLeft: '50px',
    color: colors.white,
    '&.Mui-checked': {
        color: colors.white,
    },
})

export const SubmitPrankButton = styled(Button)({
    width: 'auto',
    height: '60px',
    margin: '30px auto',
    background: colors.fireRedDark,
    color: colors.white,
    borderRadius: '30px',
    textTransform: 'none',
    '&:hover, &:focus': {
        background: colors.fireRedDark,
        outline: 'none',
    },
})

export const CopyPromoButton = styled(Button)({
    width: '45px',
    height: '45px',
    background: colors.fireRedDark,
    color: colors.white,
    borderRadius: '25px',
    textTransform: 'none',
    '&:hover, &:focus': {
        background: colors.fireRedDark,
        outline: 'none',
    },
})

export const StyledPrankPaper = styled(Paper)({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '10px',
    minWidth: '300px',
    background: colors.fireRed,
    color: colors.white,
    borderRadius: '25px',
    padding: '15px',
    margin: '0 auto',
    fontSize: '24px',
})

export const PrankShopLink = styled('a')({
    color: colors.white,
    textDecoration: 'none',
})

export const PrankWrapper = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
})
