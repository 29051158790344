import styled from '@mui/system/styled'
import { colors } from '../constants'
import { Button } from '@mui/material'

export const StyledDonationHeader = styled('h2')({
    textAlign: 'left',
    alignItems: 'left',
    justifyContent: 'left'
})

export const StyledDonationLink = styled('a')({
    color: colors.fireRed,
    textDecoration: 'none',
    fontWeight: 'bold',
    cursor: 'pointer',
    marginLeft: 6,
    marginRight: 6
})

export const StyledDonationLinkExit = styled('a')({
    color: colors.fireRed,
    marginTop: '6',
    textDecoration: 'none',
    fontWeight: 'bold',
    cursor: 'pointer',
    marginLeft: 6,
    marginRight: 6,
    margin: '4%'
})
export const DownloadButton = styled(Button)({
    width: '180px',
    background: colors.fireRedDark,
    color: '#fff',
    marginTop: '45px',
    borderRadius: '30px',
    textTransform: 'none',
    '&:hover, &:focus': {
        background: colors.fireRedDark,
        outline: 'none',
    },
})
