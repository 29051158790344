import React, { useEffect, useState } from 'react'
import { useQuery } from '../../utils'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import { StyledLink } from '../../styles'

export const Resubscribe = () => {
    const query = useQuery()
    const [subscriptionStatus, setIsConfirmed] = useState('no')

    useEffect(() => {
        async function resubscribe() {
            const id = query.get('id')
            const districts = query.get('districts')
            const isMailReceiver = query.get('isMailReceiver')
            const deviceId = query.get('deviceId')
            const deviceName = query.get('deviceName')
            const expoPushToken = query.get('expoPushToken')

            try {
                await fetch('/api/subscribe', {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    method: 'POST',
                    body: JSON.stringify({
                        id,
                        districts: districts
                            ?.split(',')
                            .map((id) => parseInt(id, 10)),
                        isMailReceiver,
                        deviceId,
                        deviceName,
                        expoPushToken,
                    }),
                })

                setIsConfirmed('yes')
            } catch (exception) {
                setIsConfirmed('error')
            }
        }

        resubscribe()
    }, []) // eslint-disable-line

    return (
        <Grid container justifyContent="center">
            <Grid item justifyContent="center">
                <Paper elevation={3}>
                    <Box
                        display="flex"
                        justifyContent="center"
                        flexDirection="column"
                        padding={5}
                    >
                        <Typography variant="h5" gutterBottom component="div">
                            {subscriptionStatus === 'no' ? 'Načítání...' : null}
                            {subscriptionStatus === 'yes'
                                ? 'Změna byla uložena'
                                : null}
                        </Typography>
                        <StyledLink href="/">Zpět</StyledLink>
                    </Box>
                </Paper>
            </Grid>
        </Grid>
    )
}
