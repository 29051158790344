import React, { useMemo } from 'react'
import parse from 'html-react-parser'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'
import * as dateFns from 'date-fns'
interface Prop {
    isOpen: boolean
    setOpen: (open: boolean) => void
    data: {
        eventRegistrationDate: string
        district: string
        noteForMedia: string
        region: string
        subtype: string
        type: string
        village: string
        partOfVillage: string
        street: string
        detail: [
            {
                eventRegistrationDate: string
                type: string
                unit: string
            }
        ]
    } | null
}
const ResponsiveDialog = (props: Prop) => {
    const theme = useTheme()
    function convertDate(myDate: string, displayFormat: string) {
        return dateFns.format(new Date(dateFns.parseISO(myDate)), displayFormat)
    }
    let convertedDate = null
    if (props.data?.eventRegistrationDate) {
        convertedDate = convertDate(
            props.data?.eventRegistrationDate,
            'dd.MM.yyyy - HH:mm'
        )
    }
    const typeSubtype = useMemo(() => {
        const { type, subtype } = props.data || {}
        const concatTypeData = [type, subtype].join(' - ')
        return concatTypeData
    }, [props.data])
    const address = useMemo(() => {
        const { district, village, partOfVillage, street } = props.data || {}
        const uniqueValues = [district, village, partOfVillage, street].filter(
            (value, index, self) => self.indexOf(value) === index && value
        )
        const concatText = uniqueValues.join(' - ')
        return concatText
    }, [props.data])

    const fullScreen = useMediaQuery(theme.breakpoints.down('lg'))
    return (
        <Dialog
            fullScreen={fullScreen}
            aria-labelledby="responsive-dialog-title"
            onClose={() => props.setOpen(false)}
            open={props.isOpen}
            maxWidth={'md'}
        >
            <DialogTitle id="responsive-dialog-title">
                {typeSubtype}
            </DialogTitle>
            <DialogTitle id="responsive-dialog-title">
                {convertedDate}
            </DialogTitle>
            {props.data?.region ? (
                <DialogTitle id="responsive-dialog-title">
                    {props.data.region}
                </DialogTitle>
            ) : null}
            {address ? (
                <DialogTitle id="responsive-dialog-title">
                    {address}
                </DialogTitle>
            ) : null}
            {props.data?.noteForMedia ? (
                <DialogContent>
                    <DialogContentText>
                        {parse(props.data?.noteForMedia)}
                    </DialogContentText>
                </DialogContent>
            ) : null}
            {props.data?.detail ? (
                <DialogContent
                    sx={{ fontWeight: 'normal' }}
                    id="responsive-dialog-title"
                >
                    {props.data?.detail.map(function (item) {
                        return (
                            <p
                                style={{
                                    fontSize: '16px',
                                    fontFamily: 'Arial, Arial Bold',
                                }}
                                key={item.unit + item.type}
                            >
                                <b>{item.unit}</b>
                                {item.type != null ? ': ' : ' '}
                                {item.type}
                            </p>
                        )
                    })}
                </DialogContent>
            ) : null}
            <DialogActions>
                <Button
                    style={{ color: 'red' }}
                    autoFocus
                    onClick={() => props.setOpen(false)}
                    sx={{ height: '30px' }}
                >
                    Ok
                </Button>
            </DialogActions>
        </Dialog>
    )
}
export default ResponsiveDialog
