const {
    REACT_APP_API_URL_DEV_ESHOP_MAIN,
    REACT_APP_API_URL_PROD_ESHOP_MAIN,
    REACT_APP_DONATE_IBAN_NUMBER,
    NODE_ENV,
} = process.env

const devEnvironment = {
    URL_ESHOP_MAIN: REACT_APP_API_URL_DEV_ESHOP_MAIN,
    URL_ESHOP_CART: `${REACT_APP_API_URL_DEV_ESHOP_MAIN}/store/cart`,
    DONATE_IBAN: REACT_APP_DONATE_IBAN_NUMBER
}

const prodEnvironment = {
    URL_ESHOP_MAIN: REACT_APP_API_URL_PROD_ESHOP_MAIN,
    URL_ESHOP_CART: `${REACT_APP_API_URL_PROD_ESHOP_MAIN}/store/cart`,
    DONATE_IBAN: REACT_APP_DONATE_IBAN_NUMBER
}

module.exports = NODE_ENV === 'production' ? prodEnvironment : devEnvironment
