import React, { createContext, useEffect, useState } from 'react'
import { AppState } from './common'

interface AppStateProviderProps {
    children: React.ReactElement
}

export const AppStateContext = createContext<Partial<AppState>>({})

export const AppStateProvider = (props: AppStateProviderProps) => {
    const [context, setContext] = useState({
        regions: [],
        districts: [],
    })

    useEffect(() => {
        async function loadData() {
            const { regions = [], districts = [] } = await fetch(
                '/api/regions'
            ).then((res) => res.json())

            // id === 7031 -> Bratislava
            setContext({
                regions: regions.filter(
                    ({ id }: { id: number }) => id !== 7031
                ),
                districts,
            })
        }

        loadData()
    }, [])

    return (
        <AppStateContext.Provider value={context}>
            {props.children}
        </AppStateContext.Provider>
    )
}
