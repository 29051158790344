import React, {
    useContext,
    useState,
    useCallback,
    useMemo,
    useEffect,
} from 'react'
import { AppStateContext } from '../../AppState'
import { AppState, District, Region } from '../../common'
import { emailRegExp, useQuery } from '../../utils'
import {
    CheckBox as CheckBoxIcon,
    CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon,
    KeyboardArrowDown as KeyboardArrowDownIcon,
} from '@mui/icons-material'
import Box from '@mui/material/Box'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import {
    AutoCompleteLabel,
    CheckboxLabel,
    CheckboxRed,
    CountSelectOption,
    SelectCircle,
    StyledCheckbox,
    StyledForm,
    StyledInput,
    SubmitButton,
} from '../../styles'
import { colors } from '../../constants'

interface RegisterFormProps {
    setNotification: (title: string) => void
}
interface AutoCompleteOption {
    label: string
    value: number
}

export const RegisterForm = ({ setNotification }: RegisterFormProps) => {
    const query = useQuery()
    const [email, setEmail] = useState('')
    const [error, setError] = useState<React.ReactElement | null>(null)
    const [hasAgreed, setHasAgreed] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    const [selectedRegionIds, setSelectedRegion] = useState<Array<number>>([])
    const [selectedDistricts, setSelectedDistricts] = useState<
        Array<number | null>
    >([])

    const { regions, districts } = useContext(AppStateContext) as AppState
    const validEmail = useMemo(() => emailRegExp.test(email), [email])

    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
    const checkedIcon = <CheckBoxIcon fontSize="small" />

    const regionsOptions: Array<AutoCompleteOption> | undefined = regions?.map(
        (region: Region) => ({
            label: region.name ?? '',
            value: region.id,
        })
    )

    const districtsOptions: Array<AutoCompleteOption> | undefined = districts
        ?.filter(({ regionId }) =>
            selectedRegionIds.some((id) => id === regionId)
        )
        .map((district: District) => ({
            value: district.id,
            label: district._name,
        }))

    useEffect(() => {
        const email = query.get('email')
        const districts = query.get('districts')

        if (email && districts) {
            setEmail(email)
            setSelectedDistricts(
                districts.split(',').map((id) => parseInt(id, 10))
            )
        }
    }, []) // eslint-disable-line

    const handleSubmit = useCallback(
        async (e: React.MouseEvent<HTMLFormElement>) => {
            e.preventDefault()

            setIsLoading(true)

            try {
                const res = await fetch('/api/subscribe', {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    method: 'POST',
                    body: JSON.stringify({
                        email,
                        districts: Array.from(selectedDistricts),
                        isMailReceiver: true,
                    }),
                })

                if (res.status !== 200) {
                    const { error } = await res.json()

                    setError(<p className="text-sm">{error}</p>)
                } else {
                    setNotification(
                        'Registrace byla úspěšná. Prosím, zkontrolujte Váš e-mail.'
                    )

                    setError(null)
                }

                setEmail('')
                setHasAgreed(false)
                setSelectedRegion([])
                setSelectedDistricts([])
            } catch (exception) {
                setError(
                    <p className="text-sm">Nastala chyba při registraci</p>
                )
            } finally {
                setIsLoading(false)
            }
        },
        [email, selectedDistricts] // eslint-disable-line
    )

    const isSubmitDisabled =
        !hasAgreed || !validEmail || !selectedDistricts.length

    const regionSelectValues = selectedRegionIds
        .map((id) =>
            (regionsOptions || []).find((region) => region.value === id)
        )
        .filter((i): i is { label: string; value: number } => !!i)
    const districtSelectValues = selectedDistricts
        .map((id) =>
            (districtsOptions || []).find((district) => district.value === id)
        )
        .filter((i): i is { label: string; value: number } => !!i)

    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('xs'))

    return (
        <StyledForm onSubmit={handleSubmit}>
            <Box display="flex" justifyContent="center">
                <StyledInput
                    type="email"
                    value={email}
                    placeholder={
                        isMobile
                            ? 'Vložte email'
                            : 'Vložte email, na který chcete posílat událost'
                    }
                    onChange={(e) => setEmail(e.target.value)}
                    variant="outlined"
                    fullWidth
                    helperText={error || null}
                />
            </Box>

            <Box
                display="flex"
                alignItems="center"
                flexDirection="column"
                mt={5}
            >
                <AutoCompleteLabel align="left" variant="h6">
                    Kraj
                </AutoCompleteLabel>
                <Autocomplete
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        width: 250,
                        height: 40,
                        background: colors.white,
                        margin: '10px 0 20px',
                        borderRadius: '30px',
                    }}
                    disableClearable
                    noOptionsText={'Žádné možnosti'}
                    multiple
                    options={regionsOptions || []}
                    disableCloseOnSelect
                    getOptionLabel={(option) => option.label}
                    onChange={(_e, option) => {
                        const currentOption = option.map((opt) => {
                            return opt.value
                        })
                        setSelectedRegion(currentOption)
                    }}
                    value={regionSelectValues ?? []}
                    popupIcon={<KeyboardArrowDownIcon />}
                    renderOption={(props, option, { selected }) => (
                        <li {...props}>
                            <CheckboxRed
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={selected}
                            />
                            {option.label}
                        </li>
                    )}
                    renderTags={(tagValue, _getTagProps) => {
                        return (
                            <CountSelectOption>
                                <SelectCircle />
                                {' Vybráno krajů: ' + tagValue.length}
                            </CountSelectOption>
                        )
                    }}
                    renderInput={(params) => (
                        <StyledInput
                            {...params}
                            variant="outlined"
                            placeholder="Vyberte kraj"
                            inputProps={{
                                ...params.inputProps,
                                autoComplete: 'no-fill', // disable autocomplete and autofill
                            }}
                        />
                    )}
                />
            </Box>
            {selectedRegionIds.length ? (
                <Box display="flex" alignItems="center" flexDirection="column">
                    <AutoCompleteLabel align="left" variant="h6">
                        Okres
                    </AutoCompleteLabel>
                    <Autocomplete
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            width: 250,
                            height: 40,
                            background: colors.white,
                            margin: '10px 0 20px',
                            borderRadius: '30px',
                        }}
                        disableClearable
                        noOptionsText={'Žádné možnosti'}
                        multiple
                        limitTags={2}
                        options={districtsOptions || []}
                        disableCloseOnSelect
                        getOptionLabel={(option) => option.label}
                        value={districtSelectValues ?? []}
                        popupIcon={<KeyboardArrowDownIcon />}
                        onChange={(_e, option) => {
                            const currentOption = option.map((opt) => {
                                return opt.value
                            })
                            setSelectedDistricts(currentOption)
                        }}
                        renderOption={(props, option, { selected }) => {
                            return (
                                <li {...props}>
                                    <CheckboxRed
                                        icon={icon}
                                        checkedIcon={checkedIcon}
                                        style={{ marginRight: 8 }}
                                        checked={selected}
                                    />
                                    {option.label}
                                </li>
                            )
                        }}
                        renderTags={(tagValue, _getTagProps) => {
                            return (
                                <CountSelectOption>
                                    <SelectCircle />
                                    {' Vybráno okresů: ' + tagValue.length}
                                </CountSelectOption>
                            )
                        }}
                        renderInput={(params) => (
                            <StyledInput
                                {...params}
                                variant="outlined"
                                placeholder="Vyberte okresy"
                                inputProps={{
                                    ...params.inputProps,
                                    autoComplete: 'no-fill', // disable autocomplete and autofill
                                }}
                            />
                        )}
                    />
                </Box>
            ) : null}
            <Box display="flex" justifyContent="center">
                <CheckboxLabel
                    control={
                        <StyledCheckbox
                            checked={hasAgreed}
                            onChange={(event) =>
                                setHasAgreed(event.target.checked)
                            }
                            name="agree"
                        />
                    }
                    label="Souhlasím s registrací mailové adresy pro využití této služby"
                />
            </Box>
            <Box display="flex" justifyContent="center">
                <SubmitButton
                    type="submit"
                    variant="contained"
                    color="inherit"
                    disabled={isLoading || isSubmitDisabled}
                >
                    {isLoading ? 'Ukládání...' : 'Uložit'}
                </SubmitButton>
            </Box>
        </StyledForm>
    )
}
