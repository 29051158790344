import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Grid, Snackbar, Alert, Typography, Box, Paper } from '@mui/material'
import { StyledLink, StyledTextfield, SubmitButton } from '../../styles'

interface Params {
    id?: string
}

export const Unsubscribe = () => {
    const params = useParams<Partial<Params>>()
    const [unsubscribeStatus, setIsUnsubscribe] = useState('no')
    const [emailValue, setEmailValue] = useState<string | undefined>(undefined)
    const [notification, setNotification] = useState<string | null>()

    const paramId = params.id

    useEffect(() => {
        const unsubscribe = async () => {
            try {
                await fetch(`/api/unsubscribe/${params.id}`)

                setIsUnsubscribe('yes')
            } catch (exception) {
                setIsUnsubscribe('error')
            }
        }
        if (paramId) {
            unsubscribe()
        }
    }, [params.id, paramId])

    const handleUnsubsribe = async () => {
        try {
            const res = await fetch('/api/unsubscribe', {
                headers: {
                    'Content-Type': 'application/json',
                },
                method: 'PUT',
                body: JSON.stringify({
                    email: emailValue,
                }),
            })
            if (res.status === 200) {
                setNotification('Byl vám zaslán email s instrukcemi.')
                setEmailValue(undefined)
            }
        } catch (exception) {
            console.log('ERROR', exception)
        }
    }

    return (
        <Grid container justifyContent="center">
            {paramId ? (
                <Grid item justifyContent="center">
                    <Paper elevation={3}>
                        <Box
                            display="flex"
                            justifyContent="center"
                            flexDirection="column"
                            padding={5}
                        >
                            <Typography
                                variant="h5"
                                gutterBottom
                                component="div"
                            >
                                {unsubscribeStatus === 'no'
                                    ? 'Probíhá odhlašování'
                                    : null}
                                {unsubscribeStatus === 'yes'
                                    ? 'Odhlášení proběhlo úspěšně'
                                    : null}
                                {unsubscribeStatus === 'error'
                                    ? 'Při odhlašování nastala chyba'
                                    : null}
                            </Typography>
                            <StyledLink href="/">Zpět</StyledLink>
                        </Box>
                    </Paper>
                </Grid>
            ) : (
                <Grid item justifyContent="center">
                    <Grid xs={12}>
                        <Box display="flex" justifyContent="center">
                            <StyledTextfield
                                name="email"
                                label="Zadejte email"
                                onChange={(e) =>
                                    setEmailValue(e.target.value.trim())
                                }
                                required={true}
                            />
                        </Box>
                        <Box
                            display="flex"
                            justifyContent="center"
                            flexDirection="column"
                            margin={4}
                        >
                            <SubmitButton
                                variant="contained"
                                color="inherit"
                                sx={{
                                    marginTop: 0,
                                }}
                                onClick={() => handleUnsubsribe()}
                            >
                                Odhlásit odběr
                            </SubmitButton>
                        </Box>
                    </Grid>
                </Grid>
            )}
            <Snackbar
                open={Boolean(notification)}
                autoHideDuration={6000}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                onClose={() => setNotification(null)}
            >
                <Alert onClose={() => setNotification(null)} severity="success">
                    {notification}
                </Alert>
            </Snackbar>
        </Grid>
    )
}
