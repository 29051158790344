import { Link } from 'react-router-dom'
import styled from '@mui/system/styled'
import Avatar from '@mui/material/Avatar'
import Drawer from '@mui/material/Drawer'
import { colors } from '../constants'

export const StyledDrawer = styled(Drawer)(({ theme }) => ({
    '& .MuiPaper-root': {
        background: 'rgb(229, 57, 53, 0.9)',
    },
}))

interface MenuItemProps {
    isActive: boolean
}

export const MenuItem = styled(Link, {
    shouldForwardProp: (prop) => prop !== 'isActive',
})<MenuItemProps>(({ theme, isActive }) => ({
    fontSize: '14px',
    color: colors.white,
    textDecoration: 'none',
    padding: '5px 15px',
    background: isActive ? colors.fireRedDark : undefined,
    borderRadius: isActive ? '7px' : undefined,
}))
export const NavTitleLink = styled(Link)(({ theme }) => ({
    display: 'block',
    padding: '0 10px',
    marginTop: '10px',
    color: colors.white,
    textDecoration: 'none',
}))
export const StyledIcon = styled(Avatar)({
    variant: 'square',
    width: 24,
    height: 24,
    padding: 12,
    background: colors.fireRed,
    borderRadius: '0',
    color: colors.white,
})
