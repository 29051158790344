import { Grid, Paper } from "@mui/material";
import { Box } from "@mui/system";
import React, { useRef } from "react";
import { FC } from "react";
import {
    StyledDonationLink,
    StyledDonationLinkExit,
    StyledDonationHeader,
    DownloadButton
} from "../../styles";
import config from "../../config";
import QRCode from "react-qr-code";
import { toPng } from 'html-to-image'

export const Donation: FC = () => {
    const {
        DONATE_IBAN,
        URL_ESHOP_MAIN
    } = config;
    const eshopLink = `${URL_ESHOP_MAIN}`

    const qrValue = `SPD*1.0*ACC:${DONATE_IBAN}*AM:${50}*CC:CZK*PT:IP*X-VS:*MSG:"Prispevek FireAlarm"`;
    const qrCodeRef = useRef(null);

    const captureQRCode = () => {
        if (qrCodeRef.current) {
            toPng(qrCodeRef.current).then((dataUrl) => {
                const downloadLink = document.createElement('a');
                downloadLink.href = dataUrl;
                downloadLink.download = 'QR-code.png';
                downloadLink.click();
            })
        }
    };
    return (
        <Grid container justifyContent="center">
            <Grid item>
                <Paper elevation={3}>
                    <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center" 
                        padding={5}
                        maxWidth={620}
                    >
                        <StyledDonationHeader>
                            Děkujeme vám, že uvažujete o podpoře naší aplikace!
                        </StyledDonationHeader>
                        <p>
                            Do budoucna máme v plánu spoustu nových funkcí a rozšíření, které pro vás budou FireAlarm vylepšovat a zachovávat v plné funkčnosti.
                            Nadále bychom také chtěli appku zachovat k dispozici zdarma a bez reklam, aby váš požitek z ní byl stále plynulý a ničím nerušený.
                            Váš příspěvek nám pomůže udržet bezproblémový chod aplikace bez reklam a investovat dále do vývoje.
                        </p>
                        <p>
                            Pro zaslání příspěvku použijte QR kód níže:
                        </p>
                        <div id="qrCodeContainer" ref={qrCodeRef}>
                            <QRCode value={qrValue} />
                        </div>
                        <DownloadButton onClick={captureQRCode}>Stáhnout QR kód</DownloadButton>
                        <p>
                            Případně můžete zaslat váš libovolný dar přímo na účet 107-9880280207/0100
                        </p>
                        <p style={{ marginTop: "7%" }}> Nadále nás také můžete podpořit prostřednictvím
                            <StyledDonationLink href={eshopLink} rel="noopener noreferrer" target="_blank">našeho e-shopu,</StyledDonationLink>
                            kde pro vás máme celoročně hromadu zajímavých produktů s hasičskou tématikou!
                        </p>
                        <StyledDonationLinkExit href="/">Zpět</StyledDonationLinkExit>
                    </Box>
                </Paper>
            </Grid>
        </Grid>
    )
}