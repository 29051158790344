import React, { useState } from 'react'
import config from '../../config'
import { Menu as MenuIcon } from '@mui/icons-material'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import Typography from '@mui/material/Typography'
import {
    MenuItem,
    MenuItemShopAppLink,
    NavTitleLink,
    StyledDrawer,
    StyledIcon,
    StyledShoppingCartMenuOutlinedIcon,
} from '../../styles'
import { colors } from '../../constants'

export const SidebarMenu = () => {
    const [openDrawer, setOpenDrawer] = useState(false)
    const [activeItem, setActiveItem] = useState<string>('home')
    const { URL_ESHOP_MAIN, URL_ESHOP_CART } = config

    return (
        <>
            <StyledDrawer
                open={openDrawer}
                onClose={() => setOpenDrawer(false)}
            >
                <Typography variant="h6" color="inherit">
                    <NavTitleLink to="/">FireAlarm</NavTitleLink>
                </Typography>
                <List>
                    <ListItem onClick={() => setOpenDrawer(false)}>
                        <ListItemText>
                            <MenuItem
                                isActive={activeItem === 'home'}
                                onClick={() => setActiveItem('home')}
                                to="/"
                            >
                                Hlavní stránka
                            </MenuItem>
                        </ListItemText>
                    </ListItem>
                    <ListItem onClick={() => setOpenDrawer(false)}>
                        <ListItemText>
                            <MenuItem
                                isActive={activeItem === 'iframe'}
                                onClick={() => setActiveItem('iframe')}
                                to="/iframe"
                            >
                                iFrame
                            </MenuItem>
                        </ListItemText>
                    </ListItem>
                    <ListItem onClick={() => setOpenDrawer(false)}>
                        <ListItemText>
                            <MenuItem
                                isActive={activeItem === 'fires'}
                                onClick={() => setActiveItem('fires')}
                                to="/fires"
                            >
                                Přehled událostí
                            </MenuItem>
                        </ListItemText>
                    </ListItem>
                    <ListItem onClick={() => setOpenDrawer(false)}>
                        <ListItemText>
                            <MenuItemShopAppLink
                                isActive={activeItem === 'store'}
                                onClick={() => {
                                    setActiveItem('store')
                                }}
                                href={URL_ESHOP_MAIN}
                            >
                                Obchod
                            </MenuItemShopAppLink>
                        </ListItemText>
                    </ListItem>
                    <ListItem onClick={() => setOpenDrawer(false)}>
                        <ListItemText>
                            <MenuItemShopAppLink
                                isActive={activeItem === 'cart'}
                                onClick={() => {
                                    setActiveItem('cart')
                                }}
                                href={URL_ESHOP_CART}
                            >
                                <StyledShoppingCartMenuOutlinedIcon />
                            </MenuItemShopAppLink>
                        </ListItemText>
                    </ListItem>
                </List>
            </StyledDrawer>
            <StyledIcon onClick={() => setOpenDrawer(!openDrawer)}>
                <MenuIcon sx={{ background: colors.fireRed }} />
            </StyledIcon>
        </>
    )
}
