import React, { useMemo, useContext, FC, useState } from 'react'
import { format, formatDistance } from 'date-fns'
import { cs } from 'date-fns/locale'
import { AppStateContext } from '../../AppState'
import Autocomplete from '@mui/material/Autocomplete'
import Box from '@mui/material/Box'
import TableBody from '@mui/material/TableBody'
import {
    CheckBox as CheckBoxIcon,
    CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon,
    KeyboardArrowDown as KeyboardArrowDownIcon,
} from '@mui/icons-material'
import {
    District,
    FireType,
    TableColumnType,
    TableFilterType,
} from '../../common'
import {
    StyledTableRow,
    StyledTableCell,
    StyledTableCellElipsis,
    StyledTable,
    LightTextField,
    GreyText,
    AutoCompleteLabel,
    CheckboxRed,
    CountSelectOption,
    SelectCircle,
    StyledBoxConteiner,
} from '../../styles'
import { colors } from '../../constants'
import { TableContainer } from '@mui/material'
import ResponsiveDialog from './Modal/modal'

interface FireTableProps {
    fires: Array<FireType>
    columns: Array<TableColumnType>
    onChangeFilter: (filter: TableFilterType) => void
    onChangeSortDirection: (sortDirection: string | null) => void
    filter: TableFilterType
    sort?: string | null
    sortDirection?: string | null
}

export const FireTable: FC<FireTableProps> = (props) => {
    const { onChangeFilter, filter, fires } = props

    const changeSelectedDistricts = (id: number) => {
        const copySelectedDistricts = [...filter.districts]
        const foundDistrictId = copySelectedDistricts.findIndex((s) => s === id)

        if (foundDistrictId !== -1) {
            copySelectedDistricts.splice(foundDistrictId, 1)
        } else {
            copySelectedDistricts.push(id)
        }
        onChangeFilter({ ...filter, districts: copySelectedDistricts })
    }
    const [eventDetail, setEventDetail] = useState(null)
    const [isOpen, setOpen] = useState<boolean>(false)
    const getDetails = async (id: number) => {
        const appVersion = 'exist';
        const eventIdData = JSON.stringify({ id: id, appVersion: appVersion });
        try {
            const data = await fetch(`/api/mobileEventsDetail/${eventIdData}`).then(
                (res) => res.json()
            )
            setOpen(true)
            setEventDetail(data.eventDetail)
        } catch (exception) {
            console.error(exception)
        }
    }
    const { districts } = useContext(AppStateContext)

    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
    const checkedIcon = <CheckBoxIcon fontSize="small" />

    const districtsOptions = useMemo(
        () =>
            districts?.map((district: District) => ({
                value: district.id,
                label: district?._name,
            })),
        [districts]
    )

    const formatedDate = (date: Date) =>
        format(new Date(date), 'dd.MM.yyyy - HH:mm', { locale: cs })

    const beforeDate = (date: Date) =>
        formatDistance(new Date(date), new Date(Date.now()), { locale: cs })

    return (
        <React.Fragment>
            {/* ------- Filtr for table with dropdown ---------- */}
            <Box
                display="flex"
                alignItems="center"
                flexDirection="column"
                mt={5}
            >
                <AutoCompleteLabel align="left" variant="h6">
                    Okres
                </AutoCompleteLabel>
                <Autocomplete
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        width: 250,
                        height: 40,
                        background: colors.white,
                        margin: '10px 0 20px',
                        borderRadius: '30px',
                    }}
                    disableClearable
                    multiple
                    options={districtsOptions || []}
                    disableCloseOnSelect
                    noOptionsText={'Žádné možnosti'}
                    getOptionLabel={(option) => option.label}
                    onChange={(_e, option) => {
                        option.forEach(
                            (opt: { label: string; value: number }) => {
                                changeSelectedDistricts(opt.value)
                            }
                        )
                    }}
                    value={
                        filter.districts.map((district) => {
                            const current = {
                                label: '',
                                value: district,
                            }
                            return current
                        }) ?? []
                    }
                    popupIcon={<KeyboardArrowDownIcon />}
                    renderOption={(props, option) => (
                        <li {...props}>
                            <CheckboxRed
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={
                                    !!filter.districts.find(
                                        (f) => f === option.value
                                    )
                                }
                            />
                            {option.label}
                        </li>
                    )}
                    renderTags={(tagValue, _getTagProps) => {
                        return (
                            <CountSelectOption>
                                <SelectCircle />
                                {' Vybráno okresů: ' + tagValue.length}
                            </CountSelectOption>
                        )
                    }}
                    renderInput={(params) => (
                        <LightTextField
                            {...params}
                            variant="outlined"
                            placeholder="Vyberte okres"
                            inputProps={{
                                ...params.inputProps,
                                autoComplete: 'no-fill', // disable autocomplete and autofill
                            }}
                        />
                    )}
                />
            </Box>
            <ResponsiveDialog
                isOpen={isOpen}
                setOpen={setOpen}
                data={eventDetail}
            />
            {/* ------- Table  ---------- */}
            <StyledBoxConteiner>
                <TableContainer>
                    <StyledTable aria-label="custom pagination table">
                        <TableBody>
                            {fires.map((row, index) => (
                                <StyledTableRow
                                    onClick={() => getDetails(row.id)}
                                    key={index}
                                    style={{ cursor: 'pointer' }}
                                >
                                    <StyledTableCell
                                        sx={{ fontWeight: 'bold' }}
                                        scope="row"
                                    >
                                        {!row.source || row.source === 'web'
                                            ? row.village
                                            : row.district}
                                    </StyledTableCell>
                                    <StyledTableCellElipsis>
                                        {!row.source || row.source === 'web'
                                            ? row.type
                                            : row.noteForMedia}
                                    </StyledTableCellElipsis>
                                    <StyledTableCell
                                        sx={{
                                            fontWeight: 'normal',
                                            color: colors.grey,
                                        }}
                                    >
                                        {formatedDate(
                                            row.eventRegistrationDate
                                        )}
                                    </StyledTableCell>
                                    <StyledTableCell
                                        sx={{
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        <GreyText> před:</GreyText>
                                        {beforeDate(row.eventRegistrationDate)}
                                    </StyledTableCell>
                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </StyledTable>
                </TableContainer>
            </StyledBoxConteiner>
        </React.Fragment>
    )
}
