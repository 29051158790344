import React, { useState } from 'react'
import { Route, Routes } from 'react-router-dom'
import useMediaQuery from '@mui/material/useMediaQuery'
import config from './config'
import { Tooltip, useTheme } from '@mui/material'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import Snackbar from '@mui/material/Snackbar'
import Alert from '@mui/material/Alert'

import { SidebarMenu, Footer } from './components'
import {
    Confirmation,
    RegisterPage,
    Resubscribe,
    Unsubscribe,
    Fires,
    CreateIframeMode,
    AprilFools,
} from './pages'
import {
    StyledRoot,
    StyledAppBar,
    MenuItemApp,
    NavTitleLinkApp,
    StyledToolbar,
    StyledShoppingCartMenuOutlinedIcon,
    MenuItemShopAppLink,
} from './styles'
import { useQuery } from './utils'
import { QueryObject } from './common'
import { Donation } from './pages/Donation'

const App = () => {
    const [notification, setNotification] = useState<string | null>()
    const [activeItem, setActiveItem] = useState<string>('home')
    const { URL_ESHOP_MAIN, URL_ESHOP_CART } = config

    const query = useQuery()

    const districtsQuery = query.get('districts')
    const dateQuery = query.get('date')
    const sortQuery = query.get('sort')
    const sortDirectionQuery = query.get('sortDirection')
    const pageQuery = query.get('page')
    const limitQuery = query.get('limit')

    const queryObj: QueryObject = {
        districtsQuery,
        dateQuery,
        sortQuery,
        sortDirectionQuery,
        pageQuery,
        limitQuery,
    }

    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('md'))

    return (
        <StyledRoot>
            <StyledAppBar position="static">
                <StyledToolbar>
                    {!isMobile && (
                        <Typography variant="h6" color="inherit">
                            <NavTitleLinkApp to="/">FireAlarm</NavTitleLinkApp>
                        </Typography>
                    )}
                    {isMobile ? (
                        <SidebarMenu />
                    ) : (
                        <Grid container spacing={3} justifyContent="center">
                            <Grid item>
                                <MenuItemApp
                                    isActive={activeItem === 'home'}
                                    onClick={() => setActiveItem('home')}
                                    to="/"
                                >
                                    Hlavní stránka
                                </MenuItemApp>
                            </Grid>
                            <Grid item>
                                <MenuItemApp
                                    isActive={activeItem === 'iframe'}
                                    onClick={() => setActiveItem('iframe')}
                                    to="/iframe"
                                >
                                    iFrame
                                </MenuItemApp>
                            </Grid>
                            <Grid item>
                                <MenuItemApp
                                    isActive={activeItem === 'fires'}
                                    onClick={() => setActiveItem('fires')}
                                    to="/fires"
                                >
                                    Přehled událostí
                                </MenuItemApp>
                            </Grid>
                            <Grid item>
                                <MenuItemShopAppLink
                                    isActive={activeItem === 'store'}
                                    onClick={() => {
                                        setActiveItem('store')
                                    }}
                                    href={URL_ESHOP_MAIN}
                                >
                                    Obchod
                                </MenuItemShopAppLink>
                            </Grid>
                            <Grid item>
                                <MenuItemShopAppLink
                                    isActive={activeItem === 'cart'}
                                    onClick={() => {
                                        setActiveItem('cart')
                                    }}
                                    href={URL_ESHOP_CART}
                                >
                                    <Tooltip
                                        title={
                                            <Typography fontSize="20px">
                                                Košík
                                            </Typography>
                                        }
                                        arrow
                                    >
                                        <StyledShoppingCartMenuOutlinedIcon />
                                    </Tooltip>
                                </MenuItemShopAppLink>
                            </Grid>
                        </Grid>
                    )}
                </StyledToolbar>
            </StyledAppBar>
            <Routes>
                <Route path="/unsubscribe" element={<Unsubscribe />} />
                <Route path="/unsubscribe/:id" element={<Unsubscribe />} />
                <Route path="/resubscribe" element={<Resubscribe />} />
                <Route
                    path="/subscription/confirm/:id"
                    element={<Confirmation />}
                />
                <Route
                    path="/iframe"
                    element={
                        <CreateIframeMode setNotification={setNotification} />
                    }
                />
                <Route path="/fires" element={<Fires urlQuery={queryObj} />} />
                <Route path="/donation" element={<Donation />} />
                <Route path="/ai-alarm" element={<AprilFools />} />
                <Route
                    path="/"
                    element={<RegisterPage setNotification={setNotification} />}
                />
            </Routes>
            <Snackbar
                open={Boolean(notification)}
                autoHideDuration={6000}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                onClose={() => setNotification(null)}
            >
                <Alert onClose={() => setNotification(null)} severity="success">
                    {notification}
                </Alert>
            </Snackbar>
            <Footer />
        </StyledRoot>
    )
}

export default App
