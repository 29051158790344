import { Link } from 'react-router-dom'
import styled from '@mui/system/styled'
import ShoppingCartOutlined from '@mui/icons-material/ShoppingCartOutlined'
import Toolbar from '@mui/material/Toolbar'
import AppBar from '@mui/material/AppBar'
import { colors } from '../constants'

interface MenuItemProps {
    isActive: boolean
}

export const StyledRoot = styled('div')({
    position: 'relative',
    fontFamily: 'Arial, Arial Bold',
})

export const StyledAppBar = styled(AppBar)({
    background: colors.fireRed,
    boxShadow: 'none',
    marginBottom: '55px',
})

export const MenuItemApp = styled(Link, {
    shouldForwardProp: (prop) => prop !== 'isActive',
})<MenuItemProps>(({ theme, isActive }) => ({
    display: 'flex',
    padding: '5px 25px',
    fontSize: '14px',
    color: colors.white,
    textDecoration: 'none',
    background: isActive ? colors.fireRedDark : undefined,
    borderRadius: isActive ? '7px' : undefined,
}))
export const MenuItemShopAppLink = styled('a', {
    shouldForwardProp: (prop) => prop !== 'isActive',
})<MenuItemProps>(({ theme, isActive }) => ({
    display: 'flex',
    padding: '5px 25px',
    fontSize: '14px',
    color: colors.white,
    textDecoration: 'none',
    background: isActive ? colors.fireRedDark : undefined,
    borderRadius: isActive ? '7px' : undefined,
    [theme.breakpoints.down('md')]: {
        padding: '5px 15px',
    },
}))
export const StyledShoppingCartMenuOutlinedIcon = styled(ShoppingCartOutlined)({
    fontSize: '18px',
    height: 'auto',
    paddingLeft: '5px',
})
export const NavTitleLinkApp = styled(Link)({
    color: colors.white,
    textDecoration: 'none',
})

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
    [theme.breakpoints.down('md')]: {
        display: 'flex',
        justifyContent: 'space-between',
    },
}))

export { StyledToolbar }
