import styled from '@mui/system/styled'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import { colors } from '../constants'

export const StyledForm = styled('form')({
    background: colors.fireRed,
    minHeight: '500px',
    maxWidth: '400px',
    borderRadius: '25px',
    padding: '35px',
    margin: '0 auto',
})
export const StyledInput = styled(TextField)({
    display: 'flex',
    justifyContent: 'center',
    height: 45,
    background: colors.white,
    border: 'none',
    borderRadius: '30px',
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            border: 'none',
        },
    },
})
export const SubmitButton = styled(Button)({
    width: '180px',
    background: colors.fireRedDark,
    color: colors.white,
    marginTop: '45px',
    borderRadius: '30px',
    textTransform: 'none',
    '&:hover, &:focus': {
        background: colors.fireRedDark,
        outline: 'none',
    },
})
