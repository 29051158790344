import styled from '@mui/system/styled'
import TableRow from '@mui/material/TableRow'
import Table from '@mui/material/Table'
import TableCell from '@mui/material/TableCell'
import TextField from '@mui/material/TextField'
import Box from '@mui/material/Box'
import { colors } from '../constants'

export const StyledBoxConteiner = styled(Box)({
    width: '80%',
    overflow: 'auto',
    marginLeft: '10%',
    marginRight: '10%',
})

export const StyledTableRow = styled(TableRow)({
    boxShadow: `0px 2px 12px -7px ${colors.black}`,
    borderRadius: '25px',
})

export const StyledTableCellElipsis = styled(TableCell)({
    width: '100%',
    maxWidth: '300px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    border: 'none',
    padding: '10px 25px',
    textAlign: 'left',
})
const StyledTableCell = styled(TableCell)({
    border: 'none',
    padding: '10px 25px',
    maxWidth: '200px',
    textAlign: 'left',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textTransform: 'none',
})
export const StyledTableCellFireMans = styled(TableCell)({
    border: 'none',
    padding: '10px 25px',
    textAlign: 'left',
})

export const StyledTableCellGrey = styled(TableCell)({
    border: 'none',
    padding: '10px 25px',
    textAlign: 'left',
    textTransform: 'none',
    whiteSpace: 'nowrap',
    color: colors.grey,
    fontWeight: 'normal',
})
export const StyledTableCellBoldGrey = styled(TableCell)({
    border: 'none',
    padding: '10px 25px',
    textAlign: 'left',
    textTransform: 'none',
    whiteSpace: 'nowrap',
    fontWeight: 'bold',
    color: colors.grey,
})
export const StyledTable = styled(Table)({
    borderSpacing: '0px 0.6rem',
    borderCollapse: 'initial',
})

export const LightTextField = styled(TextField)({
    boxShadow: `0px 2px 12px -7px ${colors.black}`,
    display: 'flex',
    alignItems: 'center',
    width: 250,
    height: 40,
    background: colors.white,
    borderRadius: '30px',
    '.MuiOutlinedInput-root': {
        padding: ' 0 10px',
        '& fieldset': {
            border: 'none',
        },
    },
})
export const GreyText = styled('span')({
    color: colors.grey,
})

export { StyledTableCell }
