import 'react-app-polyfill/ie11'
import 'url-search-params-polyfill'
import './polyfills'

import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'
import App from './App'
import { AppStateProvider } from './AppState'

ReactDOM.render(
    <Router>
        <AppStateProvider>
            <App />
        </AppStateProvider>
    </Router>,
    document.getElementById('root')
)
