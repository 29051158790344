import React, { FC, useState, FormEvent } from 'react'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import {
    DescriptionPrankText,
    StyledPrankForm,
    StyledPrankHighlight,
    StyledPrankTitle,
} from '../../styles'
import { PrankSurvey, PrankPromo } from '../../components'

export const AprilFools: FC = () => {
    const [showPrank, setShowPrank] = useState(false)

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        setShowPrank(true)
    }
    return (
        <Grid container alignItems="center" justifyContent="center">
            <Grid md={14} xs={11} item>
                {showPrank ? (
                    <PrankPromo />
                ) : (
                    <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                    >
                        <StyledPrankTitle variant="h3" color="inherit">
                            Jste připraveni na budoucnost?
                        </StyledPrankTitle>
                        <DescriptionPrankText textAlign="center">
                            Stačí vyplnit náš krátký dotazník a přelomová
                            aplikace
                            <StyledPrankHighlight>
                                FireAIAlarm
                            </StyledPrankHighlight>
                            vám bude na dosah!
                        </DescriptionPrankText>

                        <StyledPrankForm onSubmit={handleSubmit}>
                            <PrankSurvey />
                        </StyledPrankForm>
                    </Box>
                )}
            </Grid>
        </Grid>
    )
}
