import React from 'react'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import { RegisterForm } from '../../components'
import AppLogo from '../../images/fireAlarm-logo.png'
import { DescriptionRegisterPage, StyledImage, StyledTitle } from '../../styles'

interface RegisterPageProps {
    setNotification: (title: string) => void
}

export const RegisterPage = ({ setNotification }: RegisterPageProps) => {
    return (
        <Grid container alignItems="center" justifyContent="center">
            <Grid md={4} xs={12} item>
                <Box display="flex" flexDirection="column" alignItems="center">
                    <StyledImage src={AppLogo} alt="firealarm logo" />
                    <StyledTitle variant="h3" color="inherit">
                        FireAlarm
                    </StyledTitle>
                    <DescriptionRegisterPage
                        variant="body1"
                        color="textPrimary"
                        align="center"
                        paragraph
                    >
                        Žhavé informace o zásazích hasičů do vašeho emailu
                    </DescriptionRegisterPage>
                </Box>
            </Grid>
            <Grid md={4} xs={12} item>
                <RegisterForm setNotification={setNotification} />
            </Grid>
        </Grid>
    )
}
