import styled from '@emotion/styled'
import Autocomplete from '@mui/material/Autocomplete'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import { colors } from '../constants'

export const Iframe = styled('div')({
    background: colors.fireRed,
    minHeight: '500px',
    maxWidth: '715px',
    borderRadius: '25px',
    padding: '35px',
    margin: '80px auto 0',
})
export const IframeTitle = styled(Typography)({
    color: colors.white,
    fontWeight: 'bold',
    marginBottom: '10px',
})
export const IframeInfo = styled('div')({
    maxWidth: '520px',
    margin: '15px auto 0',
    padding: '15px',
    borderRadius: '20px',
    background: colors.white,
})
export const Description = styled(Typography)({
    color: colors.white,
})
export const StyledLabel = styled(Typography)({
    color: colors.white,
    marginLeft: '10px',
    marginBottom: '10px',
})
export const CopyWrapper = styled('div')({
    display: 'flex',
    justifyContent: 'center',
})
export const CopyButton = styled(Button)({
    width: '180px',
    background: colors.fireRedDark,
    color: '#fff',
    marginTop: '45px',
    borderRadius: '30px',
    textTransform: 'none',
    '&:hover, &:focus': {
        background: colors.fireRedDark,
        outline: 'none',
    },
})
export const AutocompleteFullWidth = styled(Autocomplete)({
    width: '100%',
})
